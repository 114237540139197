<template>
  <div class="container promotion-page">
    <section class="section has-text-centered is-uppercase">
      <h1 class="title">
        <strong>Special</strong> <span class="sub-title">Promotion</span>
      </h1>
    </section>
    <section class="section" v-for="sp in salePackages" :key="sp.id">
      <div class="table-container">
        <div class="table-header">
          <span class="has-text-weight-bold">{{ sp.name }}</span>
          <span class="value has-text-weight-bold">$ Value</span>
        </div>
        <b-table
          :show-header="false"
          :data="sp.products"
          :columns="columns"
          :focusable="false"
          :is-row-selectable="false"
          :mobile-cards="false"
          bordered
        ></b-table>
      </div>
    </section>
    <div class="condition is-block content">
      <h4>Condition</h4>
      <ul>
        <li>
          Any order placed within November 2020 and shipment no later than 31
          March 2021
        </li>
        <li>Apply to all participated dealers</li>
        <li>
          Qualified Gift set and Upgrade option can be included with any
          container no later than March 31, 2021
        </li>
      </ul>
    </div>
    <div class="choose-option">
      <div class="section content has-text-centered" v-show="submitted">
        <p>
          <strong class="has-text-white"
            >Thank you for your confirmation!</strong
          ><br />
          <br />
          You will receive a confirmation e-mail. If you have any question,
          <br />
          please contact our Brand Representative for more detail. <br />
        </p>
      </div>
      <div v-show="!submitted">
        <h3 class="title pt-5">Please select below options</h3>
        <section class="section">
          <div class="field" v-for="sp in salePackages" :key="sp.id">
            <b-radio v-model="radio" :native-value="sp.id">
              {{ sp.name }}
            </b-radio>
          </div>
          <div class="action mt-4">
            <b-button rounded @click="submit" :loading="isLoading"
              ><strong>CONFIRM</strong></b-button
            >
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    const columns = [
      {
        field: "name",
        label: "Option",
        centered: true,
      },
      {
        field: "description",
        label: "",
        centered: true,
      },
      {
        field: "price",
        label: "Value",
        centered: true,
      },
    ];

    return {
      columns,
      radio: null,
      isLoading: false,
      submitted: false,
    };
  },
  mounted() {
    this.$store.dispatch("promotion/getPackages");
  },
  computed: {
    ...mapState({
      salePackages: (state) => state.promotion.packages,
    }),
  },
  methods: {
    async submit() {
      if (this.isLoading == true) return;
      this.isLoading = true;
      await this.$store.dispatch("promotion/choose", { id: this.radio });
      this.submitted = true;
    },
  },
};
</script>
<style lang="scss">
.promotion-page {
  @media screen and (max-width: 768px) {
    .b-table .table-wrapper.has-mobile-cards tr:not([class*="is-"]):hover {
      background-color: transparent;
    }
  }
  color: white;
  h1.title span {
    font-family: "Helvetica Neue LT W05 25 Ult Lt";
  }
  @media screen and (min-width: 768px) {
    padding: 0rem 10rem;
  }
  .value {
    float: right;
  }

  .condition {
    padding: 0 5rem;
    h4 {
      color: white;
    }

    ul {
      margin-left: 1em;
    }

    @media screen and (max-width: 768px) {
      padding: 0 1rem;
    }
  }

  .choose-option {
    text-align: center;
    padding: 5rem;
    background-image: linear-gradient(to top, #0c244b, #0c163f);

    .title {
      color: #2966a0;
    }

    @media screen and (max-width: 768px) {
      padding: 0 1rem;
    }
  }
}

.table {
  background-image: linear-gradient(to top, #0b3064, #0b1444);
  color: white;
  @media screen and (max-width: 768px) {
    background: transparent;
  }
}

@media screen and (max-width: 768px) {
  .b-table .table-wrapper.has-mobile-cards .table {
    background-color: transparent;
  }
}

@media screen and (max-width: 768px) {
  .b-table .table-wrapper.has-mobile-cards tr:not([class*="is-"]) {
    background: transparent;
  }
}
</style>
